.listResult {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex: 1 1 0px;
  padding: 0.5em 1em;
  user-select: none;
  text-align: start;

  .overlay {
    display: flex;
    width: 100%;

    .cover {
      min-width: 70px;
      min-height: 70px;
      align-self: center;
      margin-right: 0.7em;
      background-color: #282828;
      display: grid;
      align-items: center;
      justify-content: center;
    }

    .textWrapper {
      display: flex;
      flex-direction: column;
      justify-content: center;
      width: inherit;
      margin-left: 5px;

      > h5 {
        margin: 0px;
        font-size: 15pt;
        font-weight: 500;
        word-wrap: break-word;
        display: table;
        table-layout: fixed;
        width: 100%;
        word-wrap: break-word;
        color: #fefefe;
        font-weight: 700;
        line-clamp: 2;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
      }

      .subInfo {
        display: flex;
        flex-direction: column;
        margin-bottom: 2px;
        max-height: fit-content;

        p {
          font-size: 10pt;
          margin: 0px;
          color: #b3b3b3;
          font-weight: 600;
          display: -webkit-box;
          -webkit-line-clamp: 1;
          -webkit-box-orient: vertical;
          overflow: hidden;
        }
      }
    }

    .checkImg {
      fill: #fefefe;
      height: 40px;
      width: 40px;
      align-self: center;
    }
  }
}

// .overlay:hover {
//   background-color: #282828;
// }

.playlistRow:active {
  .overlay {
    background-color: #121212;
    opacity: 0.7;
  }
}

.listResult:hover {
  background-color: #282828;
  border-radius: 5px;
}
