.homeView {
  display: flex;
  flex-direction: column;
  max-width: 800px;
  margin: 0 auto;
  width: calc(100vw - (100vw - 100%));
  background-color: #121212;
  color: #fefefe;
  // overflow-x: hidden;
  // overflow-y: hidden;

  // @media screen and (max-width: 760px){
  //   .container{ width: 100vw; margin: 0; padding: 0; }
  // }

  > h1 {
    align-self: center;
    margin-left: 1vw;
    margin-right: 1vw;
  }

  > p {
    align-self: center;
    margin-bottom: 1em;
    line-height: 150%;
    margin-left: 1em;
    margin-right: 1em;

    > a {
      color: #1db954;
      text-decoration: underline;
    }

    > a:hover {
      color: #48d6be;
    }
  }

  .loginButton {
    margin-top: 2em;
    align-self: center;
    background-color: #1db954;
    border-style: none;
    opacity: 1;
    padding: 0;
    border-radius: 25px;
    height: 50px;
    width: 180px;
    font-weight: 600;
    color: white;
    font-size: 12pt;
  }
  .loginButton:hover {
    cursor: pointer;
  }

  > .links {
    display: flex;
    flex-direction: column;

    > a {
      color: #1db954;
      text-decoration: underline;
      margin: 1em 0 0 0;
      font-size: x-large;
      font-weight: 600;
    }

    > a:hover {
      color: #48d6be;
    }

    > p {
      color: white;
      text-decoration: none;
      margin: 0.5em;
    }
  }
}
