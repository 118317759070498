.App {
  text-align: center;
  height: 100%;
}

.App h1 {
  padding-top: 10px;
}

.LoadingWrapper {
  position: absolute;
  bottom: 1em;
  width: 100%;
  display: flex;
  justify-content: center;
}

.LoadingUserDataIndicator {
  display: flex;
  background-color: rgba(51, 51, 51, 0.9);
  padding: 8px 10px;
  border-radius: 5px;
  color: #b3b3b3;
}

.LoadingUserDataIndicator span {
  margin-left: 10px;
  color: #d6d6d6;
}

.link {
  position: absolute;
  left: 10px;
  top: 10px;
}

.link svg {
  fill: white;
  padding: 5px 5px 5px 10px;
  cursor: pointer;
}